import React from 'react';

import style from './App.module.css';
import ad from './images/ad/advertise.png';
import useFetch from './hooks/useFetch';


function App() {

  
  const RefHome = React.useRef(null)
  const RefNews = React.useRef(null)

  const host = "http://preview.codium.ge/"
  // const host = "http://localhost:1337"

  const {loading, error, data} = useFetch(`${host}api/advertises?populate=*`)
  if(loading) return <p>Loading...</p>
  if(error) return console.log(error)
  console.log(data.data[0].attributes)

  const news = [
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
    {
      title: "Summer Hiking Guidebook for Gudauri",
      description: "Gudauri offers diverse trails, from the Khada valley to Bidara Main Peak. Explore the Georgian slopes on foot!Detailed Maps available",
      image: require('./images/news/test.png'),
      date: "22 March 2024"
    },  
 
   ]

  return (
    <div className={style.app}>

    <div className={style.header} ref={RefHome}>
    <div className={style.header_container}>
    <div className={style.header_logo}></div>
    <div className={style.header_navbar}>
    <div className={style.header_navbar_item} onClick={() => RefHome.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>Home</div>
    <div className={style.header_navbar_item} onClick={() => RefNews.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>News</div>
    <div className={style.header_navbar_item}>Contact</div>
    </div>
    </div> 
    </div>

    <div className={style.mainpage}>
    <div className={style.mainpage_left}>
    <div className={style.mainpage_left_header_top}>GUDAURI</div>
    <div className={style.mainpage_left_header_middle}>BUSINESS ASSOCIATION</div>
    <div className={style.mainpage_left_header_desc}>GBA - Gudauri Business Association is non governmental organization, which unions the local business for development of Gudauri resort in every direction.</div>
    <div className={style.mainpage_left_socials}>
    <div className={style.mainpage_left_mobile_socials}>
    <a className={style.mainpage_left_socials_facebookbtn} href="https://www.facebook.com/georgianmountainresortsbusinessassociation" target="_blank">
      <div className={style.mainpage_left_socials_cont} >
      <div className={style.mainpage_left_socials_facebookbtn_icon} ></div>
    <div className={style.mainpage_left_socials_facebookbtn_texts}>
    <div className={style.mainpage_left_socials_facebookbtn_head}>Visit Our</div>
    <div className={style.mainpage_left_socials_facebookbtn_desc}>Facebook</div>
      </div>
    </div>
    </a>
    <a className={style.mainpage_left_socials_fb} href="https://www.facebook.com/georgianmountainresortsbusinessassociation" target="_blank"></a>
    <div className={style.mainpage_left_socials_instagram}></div>
    </div>
    </div>
    </div>
    <div className={style.mainpage_right}></div>
    </div>

    <div className={style.news}>
    <div className={style.news_cont} ref={RefNews}>

    {news.map((i, index) => (
    <div className={style.news_item} key={index}>
    <img className={style.news_item_image} src={i.image}/>
    <div className={style.news_item_infocont}>
    <div className={style.news_item_title}>{i.title}</div>  
    <div className={style.news_item_desc}>{i.description}</div> 
    <div className={style.news_item_buttoncont}>
    <div className={style.news_item_button}>READ MORE</div>  
    <div className={style.news_item_date}>{i.date}</div>  
      </div>  
    </div>
    </div>
    ))} 

    

    </div>
    </div>

    <img className={style.advertise_image} src={`${host}${data.data[0].attributes.coverimage.data.attributes.url}`}/> 
    {/* ad */}

    <div className={style.footer}>
    <div className={style.footer_title}>Copyright © 2024 Gudauri Business Association</div>
    <a className={style.footer_codium} href="https://codium.ge/" target="_blank">Powered By <span style={{color: "rgba(147, 105, 253, 1)"}}>CODIUM.GE</span></a> 
     </div>  

    </div>
  );
}

export default App;
